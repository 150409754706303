<template>
  <div class="servicesInputWrapper">
    <div class="mainHeadlineWrapper">
      <h2 class="headline">Zusätzliche Dienstleistungen</h2>
    </div>
    <template v-for="(type, index) in availableServices" :key="type.key">
      <label class="checkboxContainer"
        style="margin-bottom: 1rem; margin-top: 2rem; display: flex; flex-direction: column; text-align: start;">
        <div v-if="!type.isManual || type.checked">
          {{ type.value }} <span v-if="type.isManual">({{ type.price }}
            €)</span>
          <input :id="`checkbox-${type.key}-${index}`" type="checkbox"
            v-model="type.checked" @change="setSelectedServices()" />
          <span class="checkmark"></span>
        </div>
        <div class="textFieldWrapper"
          v-if="hasPictureHasToBeCut && type.key === 'Beschneidung_Bild'">
          <textarea class="textField"
            placeholder="bitte machen Sie hier Ihre Angaben zum Beschnitt, bspw. Beschnitt links, rechts, oben, unten o.ä."
            cols="30" rows="5"
            @input="setPictureCutNotes(pictureHasToBeCutNotes)"
            v-model="pictureHasToBeCutNotes"></textarea>
        </div>
        <div class="disclaimer"
          v-if="edgesHaveToBeColored && type.key === 'Einfaerbung_Kanten'">
          <img src="../../../../assets/icons/info_red.svg" />
          <span>Kanten einfärben ist eine manuelle Tätigkeit.
            Farbabweichungen sind trotz genauer Farbangabe (bspw. RAL-Ton)
            möglich. Im Zweifel sprechen Sie sich bitte mit Ihrer Werkstatt
            ab.</span>
        </div>
        <div class="textFieldWrapperEdgeColor"
          v-if="edgesHaveToBeColored && type.key === 'Einfaerbung_Kanten'">
          <textarea class="textField" placeholder="Farbe, RAL-Ton angeben"
            cols="30" rows="1"
            @input="setEdgeColoringNotes(edgesHaveToBeColoredNotes)"
            v-model="edgesHaveToBeColoredNotes"></textarea>
        </div>
      </label>
    </template>
    <div class="manualInput">
      <ManualServicesInput @addService="addManualService" />
    </div>
  </div>
</template>

<script>
import ManualServicesInput from "./components/ManualServicesInput.vue";

import AdditionalServices from "@/models/Types/WorkshopAdditionalServices";
import AdditionalServices_BAUHAUS from "@/models/Types/BAUHAUS/WorkshopAddidtionalServices_BAUHAUS";
import AdditionalServices_XXXL from "@/models/Types/XXXL_GROUP/WorkshopAddidtionalServices_XXXL";

import WorkshopModes from "@/models/Types/WorkshopModes";
import DBSyncer from "@/utility/DBSyncer";
import UserTypeProvider from "@/utility/UserTypeProvider";
import UserTypes from "@/models/Types/UserTypes";

export default {
  components: {
    ManualServicesInput,
  },
  data() {
    return {
      additionalServices: (UserTypeProvider.getUserType() === UserTypes.RETAILER ? AdditionalServices : UserTypeProvider.getUserType() === UserTypes.BAUHAUS ? AdditionalServices_BAUHAUS : AdditionalServices_XXXL).map((service) => ({
        ...service,
        checked: false,
      })),
      pictureHasToBeCutNotes: "",
      edgesHaveToBeColoredNotes: ""
    };
  },

  watch: {
    workshopWorkServices: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.matchServicesToWorkshop();
        }
      },
      deep: true,
    },
    currentWorkshopWork: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.initializeServices();
        }
      },
      deep: true,
    },
  },
  methods: {
    initializeServices() {
      setTimeout(() => {
        const defaultServiceIndex = this.additionalServices.findIndex(
          (service) => service.key === "Heften_Rückseitig"
        );

        // Apply default for canvas stretch mode
        if (
          defaultServiceIndex > -1 &&
          this.currentWorkshopMode === WorkshopModes.CANVAS_STRETCH
        ) {
          this.additionalServices[defaultServiceIndex].checked = true;

          // Ensure the service is added to the workshopWorkServices
          this.setSelectedServices();
        } else {
          this.matchServicesToWorkshop();
        }
      }, 100);
    },

    matchServicesToWorkshop() {
      if (this.workshopWorkServices) {
        // Add manual services that aren't already in additionalServices
        this.workshopWorkServices.forEach((type) => {
          if (type.isManual && !this.additionalServices.some((e) => e.key === type.key)) {
            this.additionalServices.push(type);
          }
        });

        // Set checked status based on workshopWorkServices
        this.additionalServices.forEach((type) => {
          const isServiceSelected = this.workshopWorkServices.some(
            (e) => e.key === type.key
          );
          type.checked = isServiceSelected;
        });
      }
    },

    setSelectedServices() {
      // Filter out unchecked manual services
      this.additionalServices = this.additionalServices.filter(service => !service.isManual || service.checked);

      // Update workshop services with currently checked services
      this.workshopWorkServices = this.selectedTypes;

      // Sync with database
      this.syncConfiguration();
    },

    syncConfiguration() {
      DBSyncer.syncConfiguration();
      this.$store.commit("updatePrice");
    },

    checkOptionAvalaibility(obj) {
      if (obj.supportedModes) {
        return obj.supportedModes.includes(this.currentWorkshopMode);
      }
      return true;
    },

    setPictureCutNotes(notes) {
      this.$store.getters.currentWorkshopWork.picture_has_to_be_cut_notes = notes;
    },

    setEdgeColoringNotes(notes) {
      this.$store.getters.currentWorkshopWork.edges_have_to_be_colored_notes = notes;
    },

    addManualService(payload) {
      this.additionalServices.push(payload);
      this.setSelectedServices();
    }
  },

  mounted() {
    this.initializeServices();
  },

  computed: {
    selectedTypes() {
      return this.additionalServices.filter((type) => type.checked);
    },

    availableServices() {
      return this.additionalServices.filter((type) => this.checkOptionAvalaibility(type));
    },

    workshopWorkServices: {
      get() {
        return this.$store.getters.currentWorkshopWork.services;
      },
      set(value) {
        this.$store.commit("setWorkshopServices", value);
      },
    },

    hasPictureHasToBeCut() {
      return this.workshopWorkServices.find((service) => service.key === "Beschneidung_Bild");
    },

    edgesHaveToBeColored() {
      return this.workshopWorkServices.find((service) => service.key === "Einfaerbung_Kanten");
    },

    currentWorkshopMode() {
      return this.$store.getters.currentWorkshopMode;
    },

    currentWorkshopWork() {
      return this.$store.getters.currentWorkshopWork;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../../assets/sass/style.scss";

.servicesInputWrapper {
  .mainHeadlineWrapper {
    @media (max-width: $mobile) {
      width: 95vw;
    }

    @media (min-width: $mobile) and (max-width: $tablet) {
      width: 80vw;
    }

    max-width: 850px;

    .headline {
      margin-bottom: 0;
      text-align: left;
    }
  }

  .textFieldWrapper {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-start;

    .textField {
      border-radius: 25px;
      padding: 20px;
      width: 100%;
      resize: none;
      color: $ibis-black;
      border: 2px solid $ibis-grey-middle;
      //outline: 2px solid $ibis-black;
      outline: none;
      //font-family: "Raleway";

      &:focus {
        border: 2px solid $ibis-black;
      }
    }
  }

  .textFieldWrapperEdgeColor {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-start;

    .textField {
      border-radius: 25px;
      padding: 10px;
      width: 70%;
      resize: none;
      color: $ibis-black;
      border: 2px solid $ibis-grey-middle;
      //outline: 2px solid $ibis-black;
      outline: none;
      //font-family: "Raleway";

      &:focus {
        border: 2px solid $ibis-black;
      }
    }
  }

  .disclaimer {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    color: $ibis-red;

    img {
      height: 1.2rem;
    }

    @media (max-width: $mobile) {
      text-align: left;
    }
  }
}

.checkboxContainer {
  z-index: 1;
  display: flex;
  position: relative;
  padding-left: 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 0;

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    background-color: $ibis-grey-middle;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  input:checked~.checkmark {
    background-color: $ibis-red;
    transition: 0.15s;

    &:after {
      display: block;
    }
  }

  .checkmark:after {
    left: 6.5px;
    top: 4px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
</style>