<template>
  <div class="laminationWrapper">
    <CarrierInput :availableProducts="workshopCarrierMaterials"
      :roundToTwo="roundToTwo" :updatePrice="updatePrice"
      :heightValidation="heightValidation" :widthValidation="widthValidation"
      :selectAll="selectAll" :syncConfiguration="syncConfiguration"
      :initTrigger="initTrigger" @carrierInitialized="initTrigger = false">
    </CarrierInput>
    <div class="radioOptions">
      <div>
        <HangingInput class="checkboxCollection"></HangingInput>
      </div>
      <div>
        <SurfaceFinishingInput v-if="isSurfaceFinishingAvailable"
          :syncConfiguration="syncConfiguration"></SurfaceFinishingInput>
      </div>
    </div>
    <PictureValueInput></PictureValueInput>
    <div class="amount">
      <AmountInput :isWorkshopWorkQuantity="true"
        :doubleInputValidation="doubleInputValidation"></AmountInput>
    </div>
    <div class="additionalServices">
      <AdditionalServices></AdditionalServices>
    </div>
    <div class="notes">
      <ProductNotes :isWorkshopNotes="true"></ProductNotes>
    </div>
    <div class="totalPrice" v-if="currentWorkshopWork.carrier_material">
      <PositionPrice :roundToTwo="roundToTwo" :productProp="currentWorkshopWork"
        text="Gesamtpreis:" style="margin-top: 0"></PositionPrice>
    </div>
    <div class="addToOrderButtonWrapper">
      <IbisButton buttonClass="primary" buttonText="Zur Bestellung hinzufügen +"
        :buttonDisabled="!isLaminationValid" @click="addProductToOrder()">
      </IbisButton>
    </div>
  </div>
</template>
<script>
import CarrierInput from "./components/CarrierInput.vue";
import WorkshopCarrierMaterials from "@/models/Types/WorkshopCarrierMaterials";
import DBSyncer from "@/utility/DBSyncer";
import ToastTypes from "@/models/Types/ToastTypes";
import AmountInput from "../../shared/Options/AmountInput.vue";
import IbisButton from "@/components/shared/IbisButton.vue";
import ProductNotes from "../../shared/ProductNotes.vue";
import PositionPrice from "../../shared/Information/PositionPrice.vue";
import PictureValueInput from "../shared/PictureValueInput.vue";
import SurfaceFinishingInput from "./components/SurfaceFinishingInput.vue";
import HangingInput from "../framing/components/HangingInput.vue";
import {
  LaminationHigherMaxSize,
  LaminationLowerMaxSize,
  SurfaceFinishingHigherMaxSize,
  SurfaceFinishingLowerMaxSize,
} from "@/models/Types/WorkshopSurfaceFinishings";
import AdditionalServices from "../shared/AdditionalServices.vue";
export default {
  data() {
    return {
      workshopCarrierMaterials: WorkshopCarrierMaterials,
      isSurfaceFinishingAvailable: true,
      initTrigger: false,
    };
  },
  components: {
    CarrierInput,
    IbisButton,
    AmountInput,
    ProductNotes,
    PositionPrice,
    PictureValueInput,
    SurfaceFinishingInput,
    AdditionalServices,
    HangingInput,
  },
  mounted() {
    this.initTrigger = !this.initTrigger;
  },
  watch: {
    currentWorkshopWorkStretchingType() {
      if (this.currentWorkshopWorkStretchingType?.key !== "DEFAULT") {
        this.workshopCarrierMaterials = WorkshopCarrierMaterials;
        this.workshopCarrierMaterials = this.workshopCarrierMaterials.filter(
          (material) =>
            this.currentWorkshopWorkStretchingType?.supportedProducts.includes(
              material
            )
        );
        if (
          !this.currentWorkshopWorkStretchingType?.supportsSurfaceFinishings
        ) {
          this.isSurfaceFinishingAvailable = false;
        } else {
          this.checkLaminationAndSurfaceFinishingSizeLimits();
        }
      } else {
        this.workshopCarrierMaterials = WorkshopCarrierMaterials;
        this.isSurfaceFinishingAvailable = true;
      }
      this.initTrigger = !this.initTrigger;
    },
  },
  computed: {
    currentWorkshopWork() {
      return this.$store.getters.currentWorkshopWork;
    },
    currentWorkshopWorkStretchingType() {
      return this.$store.getters.currentWorkshopWork.stretching_type;
    },
    isLaminationValid() {
      if (this.currentWorkshopWork.carrier_material) {
        const userObject = JSON.parse(
          localStorage.getItem("ibisOnlinebestellungUserObject")
        );
        let notesValid = true;
        if (
          userObject.customer_type !== "Fachhandel" ||
          !userObject.customer_type
        ) {
          notesValid = this.currentWorkshopWork?.notes?.length > 0;
        }

        const edgesHaveToBeColored = this.currentWorkshopWork?.services?.some(
          (service) => service.key === "Einfaerbung_Kanten"
        );
        if (edgesHaveToBeColored) {
          if (
            this.currentWorkshopWork?.edges_have_to_be_colored_notes?.length < 5
          ) {
            return false;
          }
        }
        return (
          this.currentWorkshopWork.carrier_material.width &&
          this.currentWorkshopWork.carrier_material.height &&
          this.currentWorkshopWork.picture_value > 0 &&
          notesValid
        );
      } else {
        return false;
      }
    },
  },
  methods: {
    roundToTwo(num) {
      return +parseFloat(Math.round(num + "e+3") + "e-3");
    },
    updatePrice() {
      this.$store.commit("updatePrice");
    },
    syncConfiguration() {
      DBSyncer.syncConfiguration();
      this.updatePrice();
    },
    heightValidation(product) {
      this.validateDimensions(product, 'height');
    },
    widthValidation(product) {
      this.validateDimensions(product, 'width');
    },
    validateDimensions(product, dimension) {
      const isValidSizeInput =
        this.checkLaminationAndSurfaceFinishingSizeLimits(product);

      let value = this.allowOneDecimal(
        document.getElementById(`${dimension}Input`).value.toString()
      );
      if (isValidSizeInput) {
        if (product && !value) {
          product[`set${dimension.charAt(0).toUpperCase() + dimension.slice(1)}`](1, true);
        } else {
          product[`set${dimension.charAt(0).toUpperCase() + dimension.slice(1)}`](value, true);
        }
      }
      this.$forceUpdate();

      this.updatePrice();
    },
    selectAll(event) {
      event.target.select();
    },

    allowOneDecimal(numStr) {
      const [wholeNum, decimalNum = ""] = numStr.split(".");

      if (decimalNum.length >= 1) {
        const roundedNum = Number.parseFloat(
          `${wholeNum}.${decimalNum}`
        ).toFixed(1);
        return roundedNum;
      } else {
        return parseFloat(numStr);
      }
    },
    showToastMessage(toastMessage, type, inputId) {
      let payload = {
        showToast: true,
        toastType: type,
        message: toastMessage,
      };
      this.$store.commit("configureToast", payload);
      if (type === ToastTypes.ERROR && inputId) {
        document.getElementById(inputId).classList.add("invalid");
        setTimeout(() => {
          document.getElementById(inputId).classList.remove("invalid");
        }, 3000);
      }
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 5000);
    },
    doubleInputValidation() {
      this.validateAboveZeroAndFullNumbers();
      this.validateQuantityInput();
    },
    validateAboveZeroAndFullNumbers() {
      if (
        this.currentWorkshopWork.quantity < 1 &&
        this.currentWorkshopWork.quantity !== ""
      ) {
        this.currentWorkshopWork.quantity = 1;
      }
      this.currentWorkshopWork.quantity = this.currentWorkshopWork.quantity
        ? this.currentWorkshopWork.quantity.toFixed(0)
        : 1;
      this.updatePrice();
    },
    validateQuantityInput() {
      if (
        this.currentWorkshopWork.quantity < 1 &&
        this.currentWorkshopWork.quantity !== ""
      ) {
        this.currentWorkshopWork.quantity = 1;
      }
      this.updatePrice();
    },
    addProductToOrder() {
      this.$store.commit("addWorkshopWork");
      this.$store.commit("updateSelectedConfigurations");

      this.showToastMessage(
        "Kaschierung zur Bestellung hinzugefügt.",
        ToastTypes.SUCCESS
      );
      this.syncConfiguration();
      this.$store.commit("initializeWorkshopWork", true);
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    checkLaminationAndSurfaceFinishingSizeLimits(product) {
      let isValidSizeInput = true;
      let height = this.allowOneDecimal(
        document.getElementById("heightInput").value.toString()
      );
      let width = this.allowOneDecimal(
        document.getElementById("widthInput").value.toString()
      );

      const lower = Math.min(height, width);
      const higher = Math.max(height, width);

      if (lower > LaminationLowerMaxSize || higher > LaminationHigherMaxSize) {
        product.setWidth(Math.min(LaminationLowerMaxSize, width), true);
        product.setHeight(Math.min(LaminationHigherMaxSize, height), true);
        document.getElementById("heightInput").value = product.height;
        document.getElementById("widthInput").value = product.width;

        isValidSizeInput = false;
        this.showToastMessage(
          "Kaschierung wird in diesem Maß nicht unterstützt.",
          ToastTypes.ERROR
        );
      }
      if (
        lower > SurfaceFinishingLowerMaxSize ||
        higher > SurfaceFinishingHigherMaxSize
      ) {
        this.isSurfaceFinishingAvailable = false;
        this.$store.commit("setWorkshopSurfaceFinishing", undefined);
        this.showToastMessage(
          "Kaschierung bzw. Oberflächenveredelung in diesem Maß nicht möglich.",
          ToastTypes.ERROR
        );
      } else {
        if (this.currentWorkshopWorkStretchingType?.supportsSurfaceFinishings) {
          this.isSurfaceFinishingAvailable = true;
        }
        if (height == 124 && width == 124) {
          this.isSurfaceFinishingAvailable = true;
        }
      }

      return isValidSizeInput;
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../../../assets/sass/style.scss";

.laminationWrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .radioOptions {
    div {
      width: 70%;

      @media (max-width: $tablet) {
        width: 100%;
      }
    }

    display: flex;

    @media (max-width: $tablet) {
      flex-direction: column;
    }
  }

  .addToOrderButtonWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
